html {
  height: 100%;
}

body {
  margin: 0;
  background: #424242;
  /* align-items: center;
  justify-content: center; */
  /* background: linear-gradient(#49474c, #1e1e1e) */
  height: 100%
  /* background: #303030; */
}

#root{
  height: 100%;
}